import { useEffect, useState } from "react"
import { useStoreState, useStoreActions } from "easy-peasy"
import { navigate } from "gatsby-plugin-intl"
import { useLocation } from "@reach/router"
import { Auth } from "aws-amplify"

import { path } from "../config/routes"
import StateDestroyer from "../service/StateDestroyer"
// import StateValidator from "../service/StateValidator"
import {
  isPartnerSubdomain,
  vendorHasSubdomain,
  replaceLocationFromWorkspaceType,
} from "../helpers/user"
import { stateGenerator } from "./application"

export const useCallbackHandler = (state, storeCredentials, connectorID, options = {}) => {
  const location = useLocation()
  const { item: currentOrg, isPartner, workspaceType } = useStoreState(
    state => state.currentOrg
  )
  const [ connector, setConnector ] = useState()
  const getConnector = useStoreActions(actions => actions.connectors.getConnectorByID)
  const fetchCurrentOrg = useStoreActions(actions => actions.currentOrg.fetch)

  const invalidRedirect = () => navigate(path("ROOT"), { replace: true })

  const parsedState =
    typeof window !== "undefined" &&
    JSON.parse(window.localStorage.getItem(state))

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => fetchCurrentOrg())
      .catch(() =>
        options.onUnauthenticated
          ? options.onUnauthenticated()
          : invalidRedirect()
      )
  }, [fetchCurrentOrg, options])

  useEffect(() => {
    return () => {
      StateDestroyer.call(state)
    }
  }, [state])

  useEffect(() => {
    (async () => {
      setConnector( await getConnector(connectorID))
    })();
  }, [])

  useEffect(() => {
    if (!connector) {
      return
    }
    if (currentOrg?.id) {
      if (isPartner && vendorHasSubdomain(workspaceType) && !isPartnerSubdomain(location.host)) {
        window.location.href = replaceLocationFromWorkspaceType(
          workspaceType,
          location
        )
      // } else if (!StateValidator.call(state) && !parsedState?.redirectURL) {
      //   invalidRedirect()
      } else {
        storeCredentials().then((data) => {
          data = data || {}
          const { providerID, providerName, providerUrl, cuistoConfig, apiKey } = data
          const [_, _locale, connectorId] = location.pathname.split("/");
          const redirectUrl = parsedState?.redirectURL || path("NEW_PROJECT_CONNECTOR", { id: connectorId });
          navigate(redirectUrl, { replace: true, state: { providerID, providerName, providerUrl, cuistoConfig, apiKey, fromCallback: true } })
        })
      }
    }
  }, [currentOrg?.id, connector])

  return {
    redirectURL: parsedState?.redirectURL,
    connector
  }
}

export const useIntegrationCallbackHandler = (state, storeCredentials, integrationID, options = {}) => {
  const invalidRedirect = () => navigate(path("ROOT"), { replace: true })
  const location = useLocation()
  const { isLoaded: isCurrentOrgLoaded, item: currentOrg, workspaceType, isDefaultSet } = useStoreState(
    state => state.currentOrg
  )
  const {
    fetch: fetchCurrentUser,
  } = useStoreActions(actions => actions.currentUser)
  const { isVendor, item: currentUser, vendorLSP } = useStoreState(
    state => state.currentUser
  )
  const fetchCurrentOrg = useStoreActions(actions => actions.currentOrg.fetch)
  const parsedState =
    typeof window !== "undefined" &&
    JSON.parse(window.localStorage.getItem(state))

  useEffect(() => {
    return () => {
      StateDestroyer.call(state)
    }
  }, [state])

  useEffect(() => {
    if (!fetchCurrentOrg || !fetchCurrentUser) return;

    Auth.currentAuthenticatedUser()
    .then(() => {
      fetchCurrentUser();
      fetchCurrentOrg();
    })
    .catch(() =>
      options.onUnauthenticated
        ? options.onUnauthenticated()
        : invalidRedirect()
    )
  }, [fetchCurrentOrg, fetchCurrentUser])

  useEffect(() => {
    if (!currentUser.id || !isCurrentOrgLoaded) return;
    const type = vendorLSP || workspaceType;
    if (vendorHasSubdomain(type) && !isPartnerSubdomain(location.host)) {
      window.location.href = replaceLocationFromWorkspaceType(type, location)
      return;
    }

    const organizationID = isDefaultSet ? undefined : currentOrg.id
    storeCredentials({...parsedState, organizationID }).then(({ integrationID }) => {
      const state = stateGenerator({
        integrationID,
        fromCallback: true,
      })
      const redirectURL = parsedState?.redirectURL
        ? `${parsedState?.redirectURL}?state=${state}`
        : path("EDIT_INTEGRATION", { id: integrationID });

      navigate(redirectURL, { replace: true, state: { fromCallback: true } })
    }).catch(console.error)
  }, [currentOrg.id, currentUser.id, vendorLSP, isCurrentOrgLoaded]);

  return {
    redirectURL: parsedState?.redirectURL,
    integration: { name: integrationID }
  };
};
