export default class StateDestroyer {
  constructor(state) {
    this.state = state
  }

  static call(state) {
    return new StateDestroyer(state).call()
  }

  call() {
    if (typeof window === 'undefined') return
    window.localStorage.removeItem(this.state)
  }
}
